import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Header from "../../components/Header/header"
import Footer from "../../components/Footer/footer"
import * as styles from "../../styles/product-page.module.css"
import ProductDescription from "../../components/ProductDescription/ProductDescription"

const paragraphs = [
    "New Zealand pine has been used with great success in New Zealand and overseas for many years for the manu- facture of pallets.  The performance of New Zealand pine when used for pallet construction is a function of wood density and lumber grade based on maximum allowable knot size.",
    "The performance of New Zealand pine timber for crate and box uses is again a function of wood density and lum- ber grade, based on maximum allowable knot size and moisture content. There is ongoing potential for both New Zealand pine timber and plywood in box and crate making, specifically in the one-way export sector.",
    "Throughout the whole range of industrial packaging, New Zealand pine has a unique advantage in its very good nailing properties. Its ease of nailing, resistance to splitting and the holding properties of ring-shanked nails make it ideal for crates and boxes.",
]

const PalletsCrates = () => {
    return (
        <React.Fragment>
            <Header invert={true} />

            <div className={styles.pageTitle}>
                <h1>Pallets and Crates</h1>
            </div>

            <ProductDescription text={paragraphs}>
                <StaticImage
                    src="../../images/crates.jpg"
                    alt="Timber crates"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/pallet.jpg"
                    alt="Pine pallet"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/pallets2.jpg"
                    alt="Pine pallet"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/pallets3.jpg"
                    alt="Pine pallet"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
            </ProductDescription>

            <Footer />
        </React.Fragment>
    )
}

export default PalletsCrates
